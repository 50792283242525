import type { WatchSource } from 'vue'

export function useOverflowCheck(
  containerRef: Ref<HTMLElement | undefined>,
  options: { maxRows: number | Ref<number>, watch?: WatchSource }
) {
  const isOverflowing = ref(false)
  const scrollHeight = ref('none')
  const maxHeight = ref('none')

  const recalculateView = () => {
    const children = Array.from(containerRef.value?.children || []) as HTMLElement[]
    const rows = [...new Set(children.map(({ offsetTop, offsetHeight }) => offsetTop + offsetHeight))]
    if (rows.length) {
      isOverflowing.value = rows.length > toValue(options.maxRows)
      scrollHeight.value = `${rows[rows.length - 1]}px`
      maxHeight.value = isOverflowing.value ? `${rows[toValue(options.maxRows) - 1]}px` : scrollHeight.value
    }
  }

  useResizeObserver(containerRef, recalculateView)

  if (options.watch) watch(options.watch, recalculateView, { flush: 'post' })

  return { isOverflowing, scrollHeight, maxHeight }
}
