<template>
  <div>
    <div class="mb-3 skeleton" :style="{ 'aspect-ratio': mediaSize.width / mediaSize.height }" />
    <div v-if="showSwatches" class="mb-2 flex wrap">
      <div v-for="i in 5" :key="`swatch-${i}`" class="h-8 w-8 p-1">
        <div class="full skeleton rounded-full" />
      </div>
    </div>
    <div v-else class="mb-5 h-4 w-26 skeleton" />
    <div class="mb-3 h-2 w-18 skeleton" />
    <div class="children:skeleton md:pr-5">
      <div class="mb-1 h-3 <md:w-30" />
      <div class="mb-2 h-3 <md:w-30" />
    </div>
    <div class="h-4 w-26 skeleton" style="margin-bottom: 1.75rem" />
    <div class="mb-4 h-4 w-18 skeleton" />
    <div class="h-8 skeleton " />
  </div>
</template>

<script lang="ts" setup>
defineProps<{ showSwatches?: boolean }>()

const { mediaSize } = useAppConfig().components.product.card
</script>
