<template>
  <base-form
    :form="form"
    validate-on="submit"
    :scroll-to-error="false"
    data-test-id="form-report-issue"
    @submit="submit"
  >
    <fieldset>
      <legend class="mb-4 fw-bold" data-test-id="form-report-issue-title">
        {{ $t.whatIsTheIssue }}
        <span aria-hidden="true"> *</span>
      </legend>
      <vf-form-error v-if="submissionError" class="mb-4">
        {{ $t.unexpectedError }}
      </vf-form-error>
      <div class="grid mb-12 gap-2 md:grid-flow-col md:cols-2 md:rows-4">
        <vf-radio
          v-for="({ label, value }, i) in flagTypes"
          :key="i"
          v-model="form.flagType"
          name="issue"
          :value
          :required="i === 0"
        >
          {{ label }}
        </vf-radio>
      </div>
    </fieldset>
    <vf-form-field
      v-slot="{ attrs }"
      name="contactEmail"
      :rule="[validateRequired($t.email), validateEmail()]"
      :hint="$t.emailFormatHint"
    >
      <vf-input v-model="form.contactEmail" v-bind="attrs" type="email" required>
        {{ $t.email }}
      </vf-input>
    </vf-form-field>
    <vf-textarea
      v-model="form.flagComment"
      resizable
      class="mb-14 mt-6 md:mb-24"
      data-test-id="form-report-issue-flag-comment"
    >
      {{ $t.comments }}
    </vf-textarea>

    <div class="flex">
      <vf-button
        type="submit"
        class="mr-4 w-1/2"
        :disabled="loading"
        :loading="loading"
        data-test-id="form-report-issue-btn-flag"
      >
        {{ $t.flag }}
      </vf-button>
      <vf-button
        variant="primary-inverse"
        class="w-1/2"
        data-test-id="form-report-issue-btn-cancel"
        @click="$emit('close')"
      >
        {{ $t.cancel }}
      </vf-button>
    </div>
  </base-form>
</template>

<script lang="ts" setup>
import type { ProductReviewsFlagRequest } from '#root/api/clients/product/data-contracts'

const props = defineProps<{
  reviewId: string | number
}>()

const emit = defineEmits<{
  success: []
  close: []
}>()

const { $t } = useNuxtApp()
const { path } = useRoute()
const {
  validateRequired,
  validateEmail
} = useLocalizedValidators()

const productId = getUrlId(path, 'Product')
const loading = ref(false)
const form = reactive<ProductReviewsFlagRequest>({
  flagType: 'profane',
  flagComment: '',
  contactEmail: ''
})

const flagTypes = [
  { label: $t.profanity, value: 'profane' },
  { label: $t.wrongProduct, value: 'wrong_product' },
  { label: $t.spam, value: 'spam' },
  { label: $t.duplicate, value: 'duplicate' },
  { label: $t.copyrightViolation, value: 'copyright' },
  { label: $t.notAProductReview, value: 'not_review' },
  { label: $t.customerImage, value: 'customer_image' },
  { label: $t.other, value: 'other' }
]
const submissionError = ref(false)

async function submit() {
  loading.value = true
  try {
    await useApi().products.$reviewFlag(productId, props.reviewId.toString(), form)
    emit('success')
  }
  catch (error) {
    console.error(error)
    submissionError.value = true
  }
  finally {
    loading.value = false
  }
}
</script>
