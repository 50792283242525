import type { CountryCode } from '#types/locale'
import type { PhoneCode } from '#types/config/phoneCode'

/**
 * @param phoneCode
 * @returns the mask for the phone number if present, otherwise a default mask based on the region
 */
export const getPhoneMaskByPhoneCode = (phoneCode: string, countryCode?: Uppercase<CountryCode>): string | string[] => {
  const phoneCodes = useRuntimeConfig().public.phoneCodes as PhoneCode[]
  const mask = phoneCodes.find(
    ({ code, dialCode }) => (!countryCode || code === countryCode) && dialCode === phoneCode
  )?.mask
  return mask ?? (getRegion(countryCode || useCountryCode()) === 'NORA' ? '(XXX) XXX - XXXX' : 'XXXX - XXX - XXX')
}
