export default () => {
  const { $t } = useNuxtApp()

  return {
    validateDate: (format: 'MM/DD/YYYY' | 'DD/MM/YYYY' | 'YYYY-MM-DD' = 'YYYY-MM-DD', message?: string) =>
      validateDate (format, message || $t.validators.date),
    validateEmail: (message?: string) =>
      validateEmail(message || $t.validators.email),
    validateExactLength: (size: number, message?: string) =>
      validateExactLength(size, message || $t.validators.exactLength.replace('{size}', size.toString())),
    validateMax: (max: number, message?: string) =>
      validateMax(max, message || $t.validators.max.replace('{max}', max.toString())),
    validateMaxDate: (maxDate: Date, message?: string) =>
      validateMaxDate(maxDate, message || $t.validators.maxDate.replace('{max}', maxDate.toString())),
    validateMaxLength: (max: number, message?: string) =>
      validateMaxLength(max, message || $t.validators.maxLength.replace('{max}', max.toString())),
    validateMin: (min: number, message?: string) =>
      validateMin(min, message || $t.validators.min.replace('{min}', min.toString())),
    validateMinDate: (minDate: Date, message?: string) =>
      validateMinDate(minDate, message || $t.validators.minDate.replace('{min}', minDate.toString())),
    validateMinLength: (min: number, message?: string) =>
      validateMinLength(min, message || $t.validators.minLength.replace('{min}', min.toString())),
    validateName: (message?: string) =>
      validateName(message || $t.validators.name),
    validatePassword: (message?: string) =>
      validatePassword(message || $t.validators.password),
    validatePhone: (countryCode?: string, message?: string, regexPattern?: string) =>
      validatePhone(countryCode, message || $t.validators.phone, regexPattern),
    validateRegex: (regex: RegExp, message?: string) =>
      validateRegex(regex, message || $t.validators.regex.replace('{regex}', regex.toString())),
    validateRequired: (field: string, message?: string) =>
      validateRequired(message || $t.validators.required.replace('{field}', field)),
    validateSameAs: (field: string, message?: string) =>
      validateSameAs(field, message || $t.validators.sameAs.replace('{field}', field)),
  }
}
