<template>
  <div
    class="relative f-col"
    :class="[brandClasses.card, !hasBadge ? brandClasses.badgeOffset : '']"
    data-test-id="product-card"
  >
    <product-badge
      v-if="showBadgeAboveGallery && hasBadge"
      v-bind="data.badge"
      :class="brandClasses.badge"
      data-test-id="product-card-badge"
    />
    <product-card-gallery
      :items="data.gallery"
      :lazy
      :name="data.name"
      :show-secondary-media="showSecondaryMedia"
      :fetchpriority
      :class="[brandClasses.gallery, !hasBadge ? brandClasses.galleryNoBadge : '']"
      @click-gallery-item="navigateToPDP"
    >
      <template #badge>
        <product-badge
          v-if="!showBadgeAboveGallery && hasBadge"
          v-bind="data.badge"
          class="pointer-events-none absolute right-0"
          :class="[badgePosition === 'bottom' ? 'bottom-0' : 'top-0', brandClasses.badge]"
          data-test-id="product-card-badge"
        />
      </template>
    </product-card-gallery>
    <!-- color swatches -->
    <template v-if="swatches?.length">
      <product-swatches
        v-if="showSwatches"
        v-model="swatchModel"
        :swatches
        :lazy
        class="z-1"
        :class="brandClasses.swatches"
        @more="navigateToPDP"
      />
      <!-- show more performant text version when swatches aren't enabled -->
      <p
        v-else-if="!hideAttributes && swatches.length"
        class="text-sm underlined"
        :class="brandClasses.swatchCount"
        data-test-id="product-card-colors"
      >
        {{ showSwatchCount ? `${swatches.length} ${$pluralize($t.colors, swatches.length)}` : $t.moreColors }}
      </p>
    </template>
    <!-- eyebrow -->
    <p
      v-if="data.eyebrow?.label"
      v-bind="eyebrowStyle"
      class="truncate eyebrow"
      :class="brandClasses.eyebrow"
      data-test-id="product-card-eyebrow"
    >
      {{ data.eyebrow.label }}
    </p>
    <!-- title -->
    <base-link
      :to="data.url"
      :class="[classTitle, brandClasses.title]"
      data-test-id="product-card-title"
      @click.capture="navigateToPDP"
    >
      <template v-if="!$feature.enableProductCardGalleryCarousel">
        <span class="absolute-0" />
        <span
          class="absolute inset-x-0 top-0"
          :class="hasBadge ? brandClasses.badgeOffset : ''"
          :style="{ 'aspect-ratio': mediaSize.width / mediaSize.height }"
          @mouseenter="showSecondaryMedia = $viewport.lg"
          @mouseleave="showSecondaryMedia = false"
        />
      </template>
      {{ data.name }}
    </base-link>
    <!-- rating -->
    <div
      v-if="!hideRating && data.rating?.reviewsCount"
      class="flex items-center gap-1"
      :class="brandClasses.ratingWrapper"
    >
      <vf-rating :value="data.rating.score" :size="ratingSize" data-test-id="product-card-rating" />
      <span class="text-xs" :class="brandClasses.ratingReviewCount">
        ({{ data.rating.reviewsCount }})
      </span>
    </div>
    <!-- price -->
    <product-pricing
      :price="data.price"
      :currency="data.currency"
      :show-discount-percentage="showDiscountPercentage"
      class="mt-2 grow text-sm"
      :class="brandClasses.price"
      data-test-id="product-card-pricing"
    />
    <!-- quickshop -->
    <template v-if="quickshopMode !== 'off'">
      <base-button
        v-if="quickshopCta.iconOnly"
        :aria-label="$t.add"
        :class="brandClasses.quickshopCta"
        data-test-id="product-card-add"
        @click="openQuickshop"
      >
        <vf-icon name="quickshop" size="lg" />
      </base-button>
      <vf-button
        v-else
        :variant="quickshopCta.variant"
        :size="quickshopCta.size"
        class="relative mt-4"
        :class="brandClasses.quickshopCta"
        data-test-id="product-card-add"
        @click="openQuickshop"
      >
        <vf-icon name="plus" class="mr-1" />
        {{ $t.add }}
      </vf-button>

      <modal-or-panel-quickshop
        v-slot="props"
        :size="$viewport.lg ? 'lg' : undefined"
        position="bottom"
        class-content="<lg:p-0"
      >
        <!--
          Temporary hack to make inline quickshop work with "nested" transition
          because currently "createTemplatePromise" doesn't support dynamic transition names
         -->
        <div :class="{ 'z-2': mode === 'inline' }">
          <dialog-quickshop
            v-if="mode === 'inline'"
            ref="quickshopRef"
            v-bind="props"
            v-model:product-id="activeProduct"
            :mode
            class="transition [.nested-enter-from_&,.nested-leave-to_&]:op-0"
          />
          <dialog-quickshop
            v-else
            ref="quickshopRef"
            v-bind="props"
            :product-id="swatchId"
            :mode
          />
        </div>
      </modal-or-panel-quickshop>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { Breadcrumb, ColorOption } from '#types/product'
import type { ProductClickAction } from '#types/gtm'
import type { QuickshopMode } from '#types/catalog'
import type { Product, ProductCardExtended } from '#root/api/clients/product/data-contracts'
import type { CSSClass } from '#types/common'

const {
  breadcrumbs,
  imageFetchPriority: fetchpriority,
  product,
  quickshopMode,
  trackingCategory,
  showSwatches,
  hideRating
} = withDefaults(
  defineProps<{
    /**
     * Page breadcrumbs from the product list
     */
    breadcrumbs?: Breadcrumb[]
    /**
     * Allows custom title styling from the parent
     */
    classTitle?: CSSClass
    /**
     * Option to hide attributes
     */
    hideAttributes?: boolean
    /**
     * Controls lazy load property of the default product image
     */
    lazy?: boolean
    /**
     * Product data
     */
    product: Product | ProductCardExtended
    /**
     * Quickshop mode string provided by LaunchDarkly
     */
    quickshopMode?: QuickshopMode
    /**
     * Tracking category for the click and details analytics events
     */
    trackingCategory?: string
    /**
     * This prop allows showing swatches on the product card
     */
    showSwatches?: boolean
    /**
     * Sets displaying the discount amount as percentage
     */
    showDiscountPercentage?: boolean
    /**
     * This prop allows showing rating stars on the product card by LaunchDarkly
     */
    hideRating?: boolean
    /**
     * Set fetchpriority for the first product image
     */
    imageFetchPriority?: 'low' | 'high' | 'auto'
  }>(),
  {
    breadcrumbs: () => ([])
  }
)

const {
  badgePosition,
  brandClasses,
  mediaSize,
  quickshopCta,
  ratingSize,
  showBadgeAboveGallery,
  showSwatchCount
} = useAppConfig().components.product.card
const { $feature, $gtm, $viewport } = useNuxtApp()
const route = useRoute()
const router = useRouter()

const ModalOrPanelQuickshop = createDialog('quickshop', {
  wrapper: () => $viewport.lg ? (quickshopMode === 'inline' ? undefined : 'modal') : 'panel',
  teleport: () => !($viewport.lg && quickshopMode === 'inline')
})

const quickshopRef = ref<HTMLDivElement>()
const showSecondaryMedia = ref(false)
const mode = computed(() => $viewport.lg && quickshopMode === 'inline' ? 'inline' : 'modal')

/*
 Some products can be marketed by Style (NF0A3XEO) or Style Color (NF0A3XEOLE4).
 When marketed by style we need to find the default color from the color attributes.
*/
const isStyle = PRODUCT_STYLE_ID_REGEX.test(product?.id)
const productUrl = ref(product.url)
const defaultColor = (isStyle ? getDefaultColorOption(product.attributes, product.id) || {} : {}) as ColorOption
const activeProduct = ref(defaultColor.id || product.id)
const swatchId = ref(defaultColor.id || product.id)
const swatchModel = $feature.enablePlpSwatchDetails ? swatchId : activeProduct

// Fetch
const { data } = useApi().products.details(activeProduct, {}, {
  immediate: false,
  key: `productCard-${activeProduct.value}`,
  default: () => ({
    ...product,
    url: defaultColor.url || productUrl.value,
    eyebrow: defaultColor.eyebrow || product.eyebrow,
    badge: defaultColor.badge || product.badge
  }),
  // Temporary fix for missing data from API
  transform: (input) => ({
    ...input,
    eyebrow: input.eyebrow || product.eyebrow,
    badge: input.badge || product.badge
  })
}) as { data: Ref<Product>, pending: Ref<boolean> }

const swatches = data.value?.attributes.find((attr) => attr.type === 'color')?.options as ColorOption[]
const hasBadge = computed(() => !!data.value.badge?.label)
const eyebrowStyle = computed(() => {
  const colorHexCode = data?.value?.eyebrow?.colorHexCode
  return colorHexCode && colorHexCode !== 'None' ? { style: `color: #${colorHexCode}` } : {}
})
const categoryURL = breadcrumbs[breadcrumbs.length - 1]?.url

onClickOutside(quickshopRef, () => mode.value === 'inline' && ModalOrPanelQuickshop.close(), {
  ignore: ['#dialogs']
})

const handleGtmEvent = (payload, action: ProductClickAction) =>
  $gtm.push('product.onProductClick', payload, {
    action,
    breadcrumbs,
    category: trackingCategory,
    searchTerm: route.query.q?.toString()
  })

const navigateToPDP = (e?: PointerEvent) => {
  handleGtmEvent(data.value, 'Navigate To PDP')

  const serializedBreadcrumbs = serializeAnalyticsBreadcrumbs(breadcrumbs)
  const { path, query = {} } = getRouteFromUrl(data.value.url)

  const state = {
    breadcrumbs: serializedBreadcrumbs,
    category: trackingCategory,
    searchTerm: route.query.q,
    categoryURL
  }

  const openInNewTab = e?.metaKey || e?.ctrlKey

  if (openInNewTab) {
    localStorage.setItem(`from_plp_${data.value.id}`, JSON.stringify(state))
  }
  else {
    e?.preventDefault()
    router.push({ path, query, state })
  }
}

watch(swatchId, (id) => {
  const colorSwatch = swatches.find((swatch) => swatch.id === id) as ColorOption

  if (colorSwatch) {
    data.value.badge = colorSwatch.badge
    data.value.eyebrow = colorSwatch.eyebrow
    data.value.price = colorSwatch.price
    data.value.gallery = colorSwatch.gallery
    data.value.url = colorSwatch.url
  }
})

const openQuickshop = () => {
  if ($feature.enablePlpSwatchDetails && swatchId.value !== activeProduct.value)
    activeProduct.value = swatchId.value

  ModalOrPanelQuickshop.open({ product: data.value, categoryURL })
  handleGtmEvent(data.value, 'Open Quick Shop')
}
</script>
