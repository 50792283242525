<template>
  <form-report-issue
    v-if="!flagged"
    :review-id="reviewId"
    @success="handleSuccess"
    @close="emit('resolve')"
  />
  <template v-else>
    <base-dialog-title class="subtitle-2">
      {{ $t.postFlaggedTitle }}
    </base-dialog-title>
    <base-dialog-description class="mb-18 mt-6">
      {{ $t.postFlaggedText }}
    </base-dialog-description>
    <vf-button
      class="w-1/2"
      data-test-id="form-report-issue-btn-close"
      @click="emit('resolve')"
    >
      {{ $t.close }}
    </vf-button>
  </template>
</template>

<script lang="ts" setup>
export type Props = {
  reviewId: string | number
}
export type Emits = {
  resolve: []
  flagged: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const flagged = ref(false)

const handleSuccess = () => {
  flagged.value = true
  emit('flagged')
}
</script>
