import type { ApiErrorCode } from '#root/enums/api'

// Will be removed when this feature is implemented https://github.com/unjs/ofetch/issues/358
export async function withRetryAfterTime<T = unknown>(
  fn: () => Promise<T>,
  time: number,
  expectedErrorCodes: ApiErrorCode[] = []
): Promise<T> {
  try {
    return await fn()
  }
  catch (error) {
    assertApiError(error)
    // if no specific error code given or error code matches given one, retry after given time
    if (!expectedErrorCodes.includes(error.errorId)) {
      await new Promise((resolve) => setTimeout(resolve, time))
      return await fn()
    }

    throw error
  }
}
