import type { DateFormat } from '#types/date'

/**
 * @description Takes a string date, and its format, and returns a string date in the desired format
 * @param {string} date The date to format
 * @param {string} fromFormat The original format the date has: 'YYYY-MM-DD', 'DD-MM-YYYY' or 'MM-DD-YYYY'
 * @param {string} toFormat The format of the expected output: 'YYYY-MM-DD', 'DD-MM-YYYY' or 'MM-DD-YYYY'
 * @returns {string} The formatted date
 * @example formatDate('2021-20-03', 'YYYY-MM-DD', 'MM/DD/YYYY') returns '20/03/2021'
 * @category Utils
 */
export default (date: string, fromFormat: DateFormat = 'YYYY-MM-DD', toFormat: DateFormat = 'MM/DD/YYYY') => {
  if (!date) return ''
  const [year, month, day] = mapDateParts(date, fromFormat)
  const { separator: toSeparator, strippedFormat } = parseDateFormat(toFormat)
  switch (strippedFormat) {
    case 'yyyymmdd':
      return [year, month, day].join(toSeparator)
    case 'ddmmyyyy':
      return [day, month, year].join(toSeparator)
    case 'mmddyyyy':
      return [month, day, year].join(toSeparator)
    default:
      return [year, month, day].join(toSeparator)
  }
}
