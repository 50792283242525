import type { ProductReviewsRollupProperty } from '#root/api/clients/product/data-contracts'

/**
 * Retrieves the fit rating value from the provided product reviews rollup property.
 * @param property The product reviews rollup property object.
 * @param property.display_values display_values property
 * @param property.values values property
 * @returns The fit rating value.
 * @category Utils
 */
export default function getFitRatingValue({ display_values, values }: ProductReviewsRollupProperty) {
  if (!display_values) return 0
  let largestCount = {
    count: 0,
    label: ''
  }
  for (const obj of values) {
    if (obj.count > largestCount.count)
      largestCount = obj
  }

  return display_values.indexOf(largestCount.label) + 1
}
