export const groupBy = <K extends PropertyKey, T>(
  values: T[],
  keySelector: (item: T, index: number) => K
): Partial<Record<K, T[]>> =>
    Object.groupBy
      ? Object.groupBy(values, keySelector)
      : values.reduce((result, item, currentIndex) => {
        const key = keySelector(item, currentIndex)
        result[key] = [...(result[key] || []), item]
        return result
      }, {} as Partial<Record<K, T[]>>)
