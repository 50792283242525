<template>
  <div class="space-y-4">
    <!-- Review Headline -->
    <div class="w-1/2 flex children:skeleton gap-2 lg:w-1/3 md:w-2/3">
      <div class="h-5 w-full md:w-1/3" />
      <div class="h-5 w-1/3 md:w-1/8" />
      <div class="h-5 w-full <md:hidden" />
    </div>
    <div class="flex flex-col-reverse gap-2 lg:(flex-row gap-16 between)">
      <div class="w-full children:skeleton lg:(w-2/3 shrink-0) space-y-2">
        <div class="h-4 w-full <lg:hidden" />
        <div class="h-4 w-full <lg:hidden" />
        <div class="h-4 w-3/4 md:w-full" />
        <div class="h-4 w-3/4 md:w-2/3" />
      </div>
      <div class="w-40 children:skeleton space-y-2">
        <div class="h-4 w-full" />
        <div class="h-4 w-1/2" />
        <div class="h-4 w-3/4" />
        <div class="h-5 w-3/4" />
      </div>
    </div>
    <div v-style:w="{ sm: '33.33%', lg: '25%' }" class="f-col children:skeleton gap-2">
      <div class="h-4 w-full" />
      <div class="h-5 w-full" />
    </div>
    <div v-style:w="{ sm: '25%', lg: '12.5%' }" class="f-col gap-2">
      <div class="h-5 w-full skeleton" />
    </div>
    <div class="w-1/2 f-col gap-2 lg:w-1/3">
      <div class="h-4 w-full skeleton" />
      <div class="h-5 w-3/4 skeleton" />
      <div class="w-full flex children:skeleton gap-2 lg:w-3/4">
        <div class="h-5 w-1/6" />
        <div class="h-5 w-1/6" />
        <div class="h-5 w-2/3" />
      </div>
    </div>
  </div>
</template>
