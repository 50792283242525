<template>
  <div
    class="relative gap-x-2 gap-y-4"
    :class="[layout, layout === 'flex' && 'wrap']"
    :style="[layout === 'grid' && {
      gridTemplateColumns: `repeat(${Math.min(filter.options.length, 3)}, minmax(0, 1fr))`,
    }]"
  >
    <vf-color-filter
      v-for="({ color, id, label }, i) in filter.options"
      :key="id + i"
      v-model="model"
      :value="id"
      :color
      class="w-18"
    >
      {{ label }}
    </vf-color-filter>
    <div v-if="loading" class="absolute-0 bg-white op-50" />
  </div>
</template>

<script lang="ts" setup>
import type { ColorFilter } from '#types/filters'

withDefaults(
  defineProps<{
    filter: ColorFilter
    layout?: 'flex' | 'grid'
    loading?: boolean
  }>(),
  {
    layout: 'flex'
  }
)

const model = defineModel<string[]>()
</script>
