<template>
  <span :class="[brandClasses.badge, !icon ? brandClasses.badgeNoIcon : '']">
    <template v-if="icon">
      <img v-if="badges[icon]" :src="badges[icon]" alt="" :style="`height: ${size};`">
      <vf-icon v-else :name="icon" :size="iconSize" />
    </template>
    {{ label }}
  </span>
</template>

<script setup lang="ts">
import { filename } from 'pathe/utils'
import type { Badge } from '#types/product'

const { icon } = defineProps<Badge>()

const { brandClasses, iconSize } = useAppConfig().components.product.badge
const { size: sizes } = useAppConfig().components.vf.icon

const size = computed(() => `${sizes[iconSize] / 16}rem`)

const glob = import.meta.glob<string>('@brand/assets/badges/*.svg', { eager: true, import: 'default' })
const badges = Object.fromEntries(Object.entries(glob).map(([key, value]) => [filename(key), value]))

if (icon && !badges[icon]) {
  log.error(`
    Product/Badge: There is no local file present for ${icon}.
    No icon will be displayed.
    Please, check if your configuration is correct
  `)
}
</script>
