<template>
  <div class="relative" data-test-id="product-swatches">
    <div
      class="items-start pr-10 align-start wrap i-flex"
      :class="{ 'h-10': !expanded, 'gap-1': shape === 'rectangle' }"
      style="clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); max-height: 7.5rem;"
    >
      <vf-color-picker
        v-for="({ id, label, swatch, available }, i) in swatches"
        :ref="(el) => i === swatches.length - 1 ? lastSwatchRef = el as ComponentPublicInstance : null"
        :key="i"
        :thumbnail="swatch?.image"
        :color="swatch?.color"
        :variant="shape"
        :unavailable="!available"
        :active="modelValue === id"
        :lazy
        :name="label"
        :class="{
          'relative after:content-empty after:block after:full after:bg-white after:absolute after:left-0 after:ml-10 after:pointer-events-none after:z-1':
            i === swatches.length - 1,
        }"
        @focus="expandOnFocus"
        @click="select(id, label)"
      />
      <base-button
        class="group absolute right-0 top-0 h-10 w-10 flex center outline-none"
        tabindex="-1"
        :aria-label="$t.moreColors"
        @click="toggle"
      >
        <span class="b rounded-full i-block group-focus-visible:outline-auto" style="padding: 0.1875rem;">
          <vf-icon :name="expanded ? 'minus' : 'plus'" />
        </span>
      </base-button>
    </div>
    <base-button v-if="expanded && showMoreLink" class="mb-2 pl-2 underlined" @click="$emit('more')">
      {{ $t.moreColors }}
    </base-button>
  </div>
</template>

<script lang="ts" setup>
import type { ComponentPublicInstance } from 'vue'
import type { ColorOption } from '#types/product'

defineProps<{
  /**
   * Array of color swatches
   */
  swatches: ColorOption[]
  /**
   * Controls lazy loading of image inside the swatch
   */
  lazy?: boolean
}>()

defineEmits<{
  /**
   * Emits when more colors button is clicked
   */
  more: []
}>()

const { shape } = useAppConfig().components.product?.swatches || {}
const { $gtm } = useNuxtApp()

const modelValue = defineModel<string>()

const expanded = ref(false)
const lastSwatchRef = ref<ComponentPublicInstance | null>()
const showMoreLink = ref(false)

const ROW_HEIGHT = 40 // Swatches row height in pixels
const ROW_MAX = 3 // Maximum number of rows to show before displaying a "More Colors" link

const select = (id: string, label: string) => {
  modelValue.value = id
  $gtm.push('plpPage.onSelectColor', `${id} - ${label}`)
}

const toggle = () => {
  expanded.value = !expanded.value
  // Show "More Colors" link if there are more than 3 rows of swatches when expanded
  showMoreLink.value = expanded.value && lastSwatchRef.value?.$el.offsetTop > ROW_HEIGHT * ROW_MAX
}

const expandOnFocus = (e: { currentTarget: HTMLElement }) => {
  // Expand swatches if the focused swatch is not visible
  if (!expanded.value && e.currentTarget.offsetTop >= ROW_HEIGHT)
    expanded.value = true
}
</script>
