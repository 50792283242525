<template>
  <div class="min-h-full f-col -m-4" data-scroll-el="filtersPanel">
    <div v-if="heading" class="b-b b-grey-70 p-4">
      <h2 class="line-clamp-2 mr-8" :class="brandClasses.heading">
        {{ heading }}
      </h2>
    </div>
    <filter-display
      v-bind="{ filters, currency }"
      :max-collapsed-rows="{ sm: 2, md: 2, lg: 2 }"
      class="sticky top-0 z-1 b-b b-grey-70 bg-white px-4 py-2 "
      @remove="$emit('removeFilter', $event)"
      @clear="$emit('clearFilters')"
    />
    <div class="grow">
      <base-lazy-hydrate v-if="sorts?.length && total" when="interaction">
        <vf-accordion open :title="$t.sort" size="sm" style="border-top: 0" >
          <div class="grid gap-4 pb-6 pt-2">
            <vf-radio
              v-for="sort in sorts"
              :key="sort"
              :model-value="sortState"
              :value="sort"
              name="sort"
              @update:model-value="$emit('sortChange', $event)"
            >
              {{ $t.sorts[sort] }}
            </vf-radio>
          </div>
        </vf-accordion>
      </base-lazy-hydrate>
      <base-lazy-hydrate v-for="(filter, code, i) in filters" :key="`mobileFilter-${code}`" when="interaction">
        <vf-accordion
          :open="i === 0"
          :title="filter.label"
          size="sm"
                    data-scroll-el="filtersPanelAccordion"
        >
          <filter-options
            :model-value="filter.selected"
            :filter="filter"
            :loading="loading"
            :currency
            class="pb-6 pt-2"
            @update:model-value="$emit('filterItemChange', { code: `${code}`, selected: $event })"
          />
        </vf-accordion>
      </base-lazy-hydrate>
    </div>
    <div class="sticky bottom-0 f-col center gap-4 bg-white p-4 shadow-top">
      <vf-button v-bind="seeResultsCta" class="w-full" @click="$emit('resolve')">
        {{ replaceAll($t.seeResults, { total }) }}
      </vf-button>
      <base-button class="px-2 text-sm underlined" @click="$emit('clearFilters')">
        {{ $t.clearAll }}
      </base-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SortType } from '#types/config/pages/plp'
import type { FiltersMap } from '#types/filters'

export type Props = {
  heading?: string
  total: number
  currency?: string
  filters: FiltersMap
  sorts?: SortType[]
  loading?: boolean
  sortState?: string
}
export type Emits = {
  resolve: []
  sortChange: [payload: SortType]
  filterItemChange: [payload: { code: string, selected: string[] }]
  removeFilter: [payload: { code: string, id: string }]
  clearFilters: []
}

defineProps<Props>()
defineEmits<Emits>()

const { brandClasses, seeResultsCta } = useAppConfig().components.dialog.filters
</script>
