/**
 * @param phoneCode
 * @returns the length of the phone number for the given phone code, computed from its mask
 */

export const getPhoneLengthByPhoneCode = (phoneCode: string): number | number[] => {
  const mask = getPhoneMaskByPhoneCode(phoneCode)
  if (Array.isArray(mask))
    return mask.map((submask) => submask.match(/X/g)?.length || 0)
  return mask.match(/X/g)?.length || 0
}
