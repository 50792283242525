const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24
const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7
const SECONDS_IN_MONTH = SECONDS_IN_DAY * 30
const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365

/**
 * Generates a descriptive relative time string between two times
 * @example
 * useTimeSince(new Date(...))
 * returns something like:
 *  2 years ago
 *  3 months ago
 *  1 week ago
 *  5 hours ago
 * @param from The start date
 * @param to Optional end date
 * @returns Formatted relative time string
 * @category Utils
 */
export const useTimeSince = (from: Date | number, to: Date | number = new Date()) => {
  const toDate = typeof to === 'number' ? new Date(to) : to
  const fromDate = typeof from === 'number' ? new Date(from) : from

  const diff = Math.floor((toDate.valueOf() - fromDate.valueOf()) / 1000)

  const formatter = new Intl.RelativeTimeFormat(useLocale(), {
    style: 'long'
  })

  const years = Math.floor(diff / SECONDS_IN_YEAR)
  if (years >= 1)
    return formatter.format(-years, 'year')

  const months = Math.floor(diff / SECONDS_IN_MONTH)
  if (months >= 1)
    return formatter.format(-months, 'month')

  const weeks = Math.floor(diff / SECONDS_IN_WEEK)
  if (weeks >= 1)
    return formatter.format(-weeks, 'week')

  const days = Math.floor(diff / SECONDS_IN_DAY)
  if (days >= 1)
    return formatter.format(-days, 'day')

  const hours = Math.floor(diff / SECONDS_IN_HOUR)
  if (hours >= 1)
    return formatter.format(-hours, 'hour')

  const minutes = Math.floor(diff / SECONDS_IN_MINUTE)
  if (minutes >= 1)
    return formatter.format(-minutes, 'minute')

  return formatter.format(-diff, 'second')
}
