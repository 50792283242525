/**
 * Transforms a plain string path into an Nuxt route object
 * with split path and query params.
 * @param url Page path with possible query string.
 * @returns An object with split path and query params object.
 */
export const getRouteFromUrl = (url: string): { path: string, query?: Record<string, string> } => {
  const [path, search] = url.split('?')
  if (!search) return { path }
  const query = Object.fromEntries(new URLSearchParams(search))
  return { path, query }
}
