import type { VueInstance } from '@vueuse/core'
import type { Ref } from 'vue'

export default (
  mask: string | ((v) => string),
  opts?: { regex?: RegExp, onUpdate?: (v) => void, maskFunc?: ((x, y, z) => { formatted: string, parsed: string }) }
) => {
  const { regex, onUpdate = (v) => v, maskFunc = maskPhone } = opts || {}

  const el: Ref<HTMLInputElement | VueInstance | undefined> = ref()
  const elementInstance = computed<HTMLInputElement>(() => {
    if (el.value instanceof HTMLInputElement) return el.value
    return el.value?.$el
  })
  const value = ref<string>('')
  const clearValue = ref<string>('')
  const dynamicMask = computed(() => (typeof mask === 'string' ? mask : mask(value.value)))

  const setValue = async (_value = '', prev = '', force = false) => {
    if (_value === prev && !force) return

    const { formatted, parsed } = maskFunc(_value, dynamicMask.value, regex)
    // get current caret position
    let caretPosition = elementInstance.value?.selectionStart || 0
    value.value = formatted
    clearValue.value = parsed

    await nextTick()
    if (el.value instanceof HTMLInputElement) el.value.value = formatted
    else if (el.value?.$forceUpdate) el.value.$forceUpdate()
    onUpdate?.(parsed)

    // get mask substring for current input value
    const currentMask = dynamicMask.value.substring(0, formatted?.length || 0)

    // check if mask was applied and calculate caret offset
    // remove last character from input since it's a user input
    let maskOffset = 0
    for (let c = currentMask.length - 2; c >= 0; c--) {
      if (currentMask[c] !== 'X')
        maskOffset++
      else
        break
    }

    caretPosition = caretPosition + maskOffset

    // set selection position
    if (caretPosition === 0) {
      nextTick(() => {
        elementInstance.value?.setSelectionRange(0, 0)
      })
    }
    else {
      nextTick(() => {
        elementInstance.value?.setSelectionRange(caretPosition, caretPosition)
      })
    }
  }

  watch(value, (cur, prev) => setValue(cur, prev))
  watch(clearValue, (cur, prev) => setValue(cur, prev))
  watch(dynamicMask, () => {
    setValue(clearValue.value, '', true)
  })

  return {
    value,
    clearValue,
    el
  }
}
