<template>
  <ul :class="brandClasses.list">
    <li v-for="{ label, thumbnail } of data" :key="label" :class="brandClasses.listItem">
      <base-button :class="brandClasses.button" @click="ModalAccordion.open({ data, title })">
        <base-picture
          v-if="thumbnail"
          :src="thumbnail"
          :alt="label"
          width="64"
          height="64"
          fit="contain"
          :class="brandClasses.picture"
        />
        <span v-if="label" class="line-clamp-2 break-words">
          {{ label }}
        </span>
      </base-button>
    </li>
    <modal-accordion size="sm" />
  </ul>
</template>

<script setup lang="ts">
import type { ProductDetailContent } from '#types/product'

defineProps<{
  data: ProductDetailContent[]
  title: string
}>()

const { brandClasses } = useAppConfig().components.product.details.modalGrid

const ModalAccordion = createModal('accordion-modal')
</script>
