<template>
  <ul :class="brandClasses.list" data-test-id="product-details-bulletin">
    <li v-for="({ label, text, url }, i) in data" :key="i" class="ml-5">
      <base-link v-if="url" :to="url">
        {{ text || label }}
      </base-link>
      <span v-else>
        {{ text || label }}
      </span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { ProductDetailContent } from '#types/product'

defineProps<{
  data: ProductDetailContent[]
}>()

const { brandClasses } = useAppConfig().components.product.details.bulletin
</script>
