<template>
  <filter-option-tile-expanded
    v-model="modelValue"
    v-bind="{ filter: filterValue, layout, loading }"
  />
</template>

<script lang="ts" setup>
import type { SizeFilter } from '#types/filters'

const props = withDefaults(
  defineProps<{
    filter: SizeFilter
    layout?: 'flex' | 'grid'
    loading?: boolean
  }>(),
  {
    layout: 'flex'
  }
)

const modelValue = defineModel<string[]>()

const filterValue = {
  ...props.filter,
  options: props.filter.options.map(({ label, ...option }) => ({
    ...option,
    label: `${label}%`
  }))
}
</script>
