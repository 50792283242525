<template>
  <ul class="flex gap-x-2 gap-y-4 wrap">
    <li v-for="{ label, thumbnail, url } of data" :key="label" class="w-22">
      <component :is="url ? BaseLink : 'div'" :to="url || undefined" class="f-col items-center">
        <base-picture
          v-if="thumbnail || benefitsAttributeImages"
          :src="thumbnail || `/img/benefits-attribute/${label?.toLowerCase().split(' ').join('-')}.svg`"
          :alt="label"
          width="64"
          height="64"
          class="mb-4 mt-3 aspect-square h-16 w-16"
          :class="{ invisible: !thumbnail && !benefitsAttributeImages }"
          fit="contain"
        />
        <p v-if="label" class="line-clamp-2 break-words text-center">
          {{ label }}
        </p>
      </component>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { BaseLink } from '#components'
import type { ProductDetailContent } from '#types/product'

defineProps<{
  data: ProductDetailContent[]
}>()

const { benefitsAttributeImages } = useAppConfig().pages.pdp.accordions || {}
</script>
