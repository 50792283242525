<template>
  <label
    class="relative max-w-full flex duration"
    :class="[
      sortBrandClasses.label,
      disabled ? 'bg-grey-90 b-grey-50 c-grey-30' : selectBrandClasses.borderColor,
      {
        'bg-white cursor-pointer focus-within:b-grey-10 hover:b-grey-10': !disabled,
        'b-red-30': invalid,
      }]"
    data-test-id="select-sort"
  >
    <span class="sr-only" data-test-id="select-sort-label">
      {{ label }}
    </span>
    <base-select
      v-model="modelValue"
      :options
      :placeholder
      :disabled
      :label-formatter="labelFormatter"
      class="full flex items-center !text-sm"
      :class="[
        sortBrandClasses.text,
        { 'c-grey-30': disabled },
        modelValue ? selectBrandClasses.textSelected : 'c-grey-20',
      ]"
      @update:model-value="$emit('change', $event)"
    />
    <vf-icon
      name="chevron"
      :size="iconSize"
      dir="down"
      class="pointer-events-none"
      :class="[sortBrandClasses.icon, disabled ? 'c-grey-50' : 'c-grey-10']"
    />
  </label>
</template>

<script lang="ts" setup>
import type { SelectOption } from '#types/components/base/select'

const { label } = defineProps<{
  /**
   * Defines the label text
   */
  label: string
  /**
   * Defines list of options
   */
  options: (string | SelectOption)[] | (() => Promise<SelectOption[]>)
  /**
   * Defines placeholder when no selection
   */
  placeholder?: string
  /**
   * Defines disabled state
   */
  disabled?: boolean
  /**
   * Defines invalid state
   */
  invalid?: boolean
}>()

defineEmits<{ change: [value: string | number | object] }>()

const {
  select: {
    brandClasses: selectBrandClasses,
    iconSize,
    sort: { brandClasses: sortBrandClasses }
  }
} = useAppConfig().components.vf.form
const modelValue = defineModel<string | number | object>()

const labelFormatter = (option?: SelectOption) => `${label}: ${option?.label ?? ''}`
</script>
