<template>
  <div
    class="relative gap-4"
    :class="[
      layout,
      layout === 'grid' && filter.options.length > 7
        ? filter.options.length > 14
          ? 'cols-3' : 'cols-2'
        : 'flex-col',
    ]"
  >
    <vf-checkbox
      v-for="({ id, items, label }, i) in filter.options"
      :key="id + i"
      v-model="model"
      :value="id"
      style="max-width: 13.625rem"
    >
      <span class="mt-0.5 flex transform gap-1 -translate-x-1">
        <span class="line-clamp-3 text-sm">
          {{ label }}
        </span>
        <span class="mt-0.5 text-xs c-grey-20">
          ({{ items }})
        </span>
      </span>
    </vf-checkbox>
    <div v-if="loading" class="absolute-0 bg-white op-50" />
  </div>
</template>

<script lang="ts" setup>
import type { CheckboxFilter } from '#types/filters'

withDefaults(
  defineProps<{
    filter: CheckboxFilter
    layout?: 'flex' | 'grid'
    loading?: boolean
  }>(),
  {
    layout: 'flex'
  }
)

const model = defineModel<string[]>()
</script>
