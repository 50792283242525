<template>
  <vf-input v-bind="{ disabled, invalid, variant }">
    <slot>{{ $t.customAmount }}</slot>
    <template #input>
      <div class="flex" :class="{ 'flex-row-reverse': !isCurrencyOnLeft }">
        <span v-if="model">
          {{ currencySymbol }}
        </span>
        <base-input
          ref="inputRef"
          v-model="maskedPrice"
          type="text"
          :class="{ [baseInputClasses]: model }"
          :disabled="disabled"
          :placeholder="placeholder"
          @change="$emit('change', $event)"
        />
      </div>
    </template>
  </vf-input>
</template>

<script lang="ts" setup>
/**
 * This component expect the model value to be a string in the format YYYY-MM-DD
 * It will emit the value in the same format, YYYY-MM-DD
 * Locale formats are handled internally and should be specified as a prop when adding EMEA support
 */
const props = withDefaults(
  defineProps<{
    modelValue: string
    currency: string
    placeholder?: string
    disabled?: boolean
    invalid?: boolean
    variant?: 'inverse'
  }>(),
  {
    disabled: false
  }
)

defineEmits<{
  change: [value: string]
}>()

const model = defineModel<string>({ required: true })
const { value: maskedPrice, el: inputRef } = useMask('XXXX', {
  regex: /\d/,
  onUpdate: (v) => model.value = v
})

const currencySymbol = getCurrencySymbol(useLocale(), props.currency)
const isCurrencyOnLeft = useFormattedPrice(0, props.currency).toString().startsWith(currencySymbol)

const baseInputClasses = computed(() => isCurrencyOnLeft ? 'pl-1' : 'text-right pr-1')

watch(() => props.modelValue, (newValue) => {
  const formattedValue = newValue ? `${currencySymbol}${newValue}` : ''

  if (formattedValue !== maskedPrice.value)
    maskedPrice.value = formattedValue
}, { immediate: true })
</script>
