<template>
  <div
    class="relative gap-x-2 gap-y-4"
    :class="[layout, layout === 'flex' && 'wrap']"
    :style="[layout === 'grid' && {
      gridTemplateColumns: `repeat(${Math.min(filter.options.length, 3)}, minmax(0, 1fr))`,
    }]"
  >
    <base-toggle
      v-for="({ id, label }, i) in filter.options"
      :key="id + i"
      v-model="model"
      :value="id"
      data-test-id="vf-size-filter"
    >
      <span
        class="h-10 flex grow center b text-center text-xs duration peer-focus-visible:outline-auto"
        :class="[
          brandClasses.base,
          model?.includes?.(id)
            ? brandClasses.selected
            : 'bg-white b-grey-40 c-grey-10',
        ]"
      >
        <span class="w-full select-none px-3" data-test-id="vf-size-picker-text">
          {{ label }}
        </span>
      </span>
    </base-toggle>
    <div v-if="loading" class="absolute-0 bg-white op-50" />
  </div>
</template>

<script lang="ts" setup>
import type { SizeFilter } from '#types/filters'

withDefaults(
  defineProps<{
    filter: SizeFilter
    layout?: 'flex' | 'grid'
    loading?: boolean
  }>(),
  {
    layout: 'flex'
  }
)

const { brandClasses } = useAppConfig().components.filter.option.tileExpanded
const model = defineModel<string[]>()
</script>
