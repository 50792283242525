<template>
  <!-- Variant Selection -->
  <base-form ref="formRef" :form validate-on="submit">
    <product-variant-selection
      v-model="attributeSelection"
      :product
      :pending
      :attribute-selection-labels="attributeSelectionLabels"
      :show-notify-me="false"
      show-badge
      :color-swatch="swatches?.variant"
      :color-size="swatches?.size"
      @select-color="(id, url, optionLabel) => $emit('selectCardStyle', id, url, optionLabel)"
      @select-size="(type, value, label) => $emit('selectCardAmount', type, value, label)"
    />
    <div class="mt-2">
      <vf-form-field
        v-slot="{ attrs }"
        name="amount"
        :rule="!attributeSelection.size ? [
          validateMin(customAmountRange.minAmount, replaceAll($t.giftCardMinAmount, { amount: minAmount })),
          validateMax(customAmountRange.maxAmount, replaceAll($t.giftCardMaxAmount, { amount: maxAmount })),
        ] : []"
      >
        <input-price v-model="form.amount" v-bind="attrs" :currency="product?.currency" />
        <p v-if="!attrs.invalid" class="b-b b-grey-70 pb-4 text-sm c-grey-20">
          {{ replaceAll($t.giftCardAmountMustBe, { amount: minAmount }) }}
        </p>
      </vf-form-field>
      <!-- Recipent details -->
      <div class="mb-2 mt-4 flex items-center space-x-2">
        <div class="h-4 w-4 flex justify-center rounded-full bg-grey-10">
          <span class="text-xs c-white fw-bold">3</span>
        </div>
        <p class="text-sm fw-bold">
          {{ $t.recipientDetails }}:
        </p>
      </div>
      <vf-form-field
        v-slot="{ attrs }"
        name="fullName"
        :rule="[
          validateRequired($t.recipientsName),
          validateName(),
          validateMinLength(2),
          validateMaxLength(60),
        ]"
      >
        <vf-input v-model="form.fullName" v-bind="attrs" required>
          {{ $t.recipientsName }}
        </vf-input>
      </vf-form-field>
      <vf-form-field
        v-slot="{ attrs }"
        name="email"
        :rule="[validateRequired($t.recipientsEmail), validateEmail()]"
        :hint="$t.emailFormatHint"
      >
        <vf-input v-model="form.email" v-bind="attrs" type="email" required class="mt-2">
          {{ $t.recipientsEmail }}
        </vf-input>
      </vf-form-field>
      <vf-form-field
        v-slot="{ attrs }"
        name="message"
        :rule="messageMaxLength ? [validateMaxLength(messageMaxLength)] : []"
        :hint="messageMaxLength ? replaceAll($t.maxCharacters, { max: messageMaxLength }) : undefined"
      >
        <div class="mt-4 b-t b-grey-70 pt-4 space-y-2">
          <p class="text-sm fw-bold">
            {{ $t.optionalMessage }}:
          </p>
          <vf-textarea
            v-model="form.message"
            v-bind="attrs"
            :props-textarea="{ maxlength: messageMaxLength }"
            resizable
          >
            <span class="sr-only">{{ $t.optionalMessage }}</span>
          </vf-textarea>
        </div>
      </vf-form-field>
      <slot name="formCta" :form="form" :size-attributes="sizeAttributes" />
    </div>
  </base-form>
</template>

<script lang="ts" setup>
import type { BaseForm as BaseFormType } from '#components'
import type { UseFormattedPriceOptions } from '#types/composables/useFormattedPrice'
import type { AttributeSelection, GiftCardFormFields, ProductAttribute, ProductAttributeType, ProductExtended } from '#types/product'

const props = defineProps<{
  product: ProductExtended
  pending: boolean
  error: boolean
}>()

const emit = defineEmits<{
  selectCardStyle: [id: string, url: string, optionLabel: string]
  selectCardAmount: [type: Exclude<ProductAttributeType, 'color'>, value: string, label: string]
  resetError: []
}>()

defineSlots<{
  formCta: (props: { form: GiftCardFormFields, sizeAttributes: ProductAttribute[] }) => void
}>()

const attributeSelection = defineModel<AttributeSelection>('attributeSelection', { required: true })

const form = defineModel<GiftCardFormFields>('form', {
  default: {
    fullName: '',
    email: '',
    message: '',
    policy: false,
    amount: '',
    size: ''
  }
})

const { messageMaxLength, swatches } = useAppConfig().components.form.giftCard || {}
const { enableColorAsAttribute } = useAppConfig().pages.pdp || {}
const { validateEmail, validateMaxLength, validateName, validateRequired } = useLocalizedValidators()
const { $t } = useNuxtApp()

const formRef = ref<InstanceType<typeof BaseFormType>>()

const productColor = computed(() =>
  props.product?.attributes
    .find((attribute) => attribute.type === 'color')
    ?.options.find((color) => color.id === props.product.id)
)

const attributeSelectionLabels = computed<Record<string, string>>(() => {
  const labels = {}
  props.product.attributes.forEach((attribute) => {
    labels[attribute.label]
      = attribute.type === 'color'
        ? productColor.value?.label
        : attribute.options.find((option) => option.value === attributeSelection[attribute.type])?.label
  })
  return labels
})

const customAmountRange = computed(() => {
  const variant = props.product?.variants.find((variant) => variant.minDigitalProductAmount)
  return {
    minAmount: variant?.minDigitalProductAmount || 0,
    maxAmount: variant?.maxDigitalProductAmount || 0
  }
})

const extraCurrencyProps = { trailingZeroDisplay: 'stripIfInteger' } satisfies UseFormattedPriceOptions
const minAmount = useFormattedPrice(customAmountRange.value.minAmount, props.product?.currency, extraCurrencyProps)
const maxAmount = useFormattedPrice(customAmountRange.value.maxAmount, props.product?.currency, extraCurrencyProps)

const attributes = computed(() => {
  // Check if there is any attribute option that needs to be marked as unavailable according to its inventory
  return props.product?.attributes.map((attribute) => {
    return {
      ...attribute,
      options: attribute.options.filter(({ label }) => label !== 'X'),
    }
  }) || []
})

const sizeAttributes = computed(() => (attributes.value?.filter(({ type }) => type !== 'color') || []))

if (!attributeSelection.value.size) {
  const firstSize = sizeAttributes.value[0]?.options[0]

  emit('selectCardAmount', 'size', firstSize?.value, firstSize?.label)
}

const preselectColor = () => {
  const { options } = attributes.value.find(({ type }) => enableColorAsAttribute && type === 'color') || {}

  if (!options) return

  const availableOptions = options.filter(({ available }) => available)

  if (options?.length === 1)
    attributeSelection.value.color = options[0].value
  else if (availableOptions.length === 1)
    attributeSelection.value.color = availableOptions[0].value
  else
    attributeSelection.value.color = (options?.find(({ defaultColor }) => defaultColor) || options[0])?.value
}

watch(() => form.value.amount, (value) => {
  const { options } = sizeAttributes.value[0] || {}

  if (value)
    attributeSelection.value.size = ''
  else if (!attributeSelection.value.size)
    attributeSelection.value.size = options[0]?.value || ''
})

watch(() => attributeSelection.value.size, (value) => {
  if (value) {
    form.value.amount = ''
    formRef.value?.reset()
  }
})

watch(() => Object.values(form.value), () => {
  if (props.error) {
    formRef.value?.reset()
    emit('resetError')
  }
})

onBeforeUnmount(() => {
  form.value.fullName = ''
  form.value.email = ''
  form.value.message = ''
  form.value.amount = ''
  form.value.size = ''
  form.value.policy = false
})

onMounted(preselectColor)

defineExpose({
  validate: (force?: boolean) => formRef.value?.validate(force),
  reset: () => formRef.value?.reset()
})
</script>
