/**
 * @param phone - any phone number
 * @param mask - mask the phone should be formatted into
 * @param regex - regex to detect valid symbols
 * @returns an object with clear and formatted phone number
 */
export const maskPhone = (phone: string | number, mask: string, regex = /\w/) => {
  const parser = (character: string) => character.match(regex)?.[0] || ''

  const parsed = phone.toString().split('').reduce((v, c) => v + parser(c), '')
  const formatted = mask.split('').reduce(
    ({ value: _, count }, c) => {
      if (count === parsed.length) return { value: _, count }
      if (c !== 'X') return { count, value: _ + c }
      return {
        value: _ + (parsed[count++] || ''),
        count
      }
    },
    {
      value: '',
      count: 0
    }
  ).value

  return { parsed, formatted }
}
