import type { DateFormat } from '#types/date'

/**
 * Removes all the separators from the format string and converts it to lower case
 * @param dateFormat The format of the date: 'YYYY-MM-DD', 'DD-MM-YYYY' or 'MM-DD-YYYY'
 * @returns An object with the separator and the stripped date format
 * @category Utils
 */
export const parseDateFormat = (dateFormat: string) => {
  const index = dateFormat.search(/[/\-.]/)
  const separator = index !== -1 ? dateFormat[index] : ''
  return {
    separator,
    strippedFormat: dateFormat.replaceAll(separator, '').toLocaleLowerCase()
  }
}

/**
 * @description Maps the date parts based on the input format and returns it always as [year, month, day]
 * @param date The date string to be mapped
 * @param inputFormat {DateFormat} the format of the passed date
 * @returns An array with the date parts as [year, month, day]
 * @category Utils
 */
export const mapDateParts = (date: string, inputFormat: DateFormat = 'YYYY-MM-DD') => {
  const { separator, strippedFormat } = parseDateFormat(inputFormat)
  if (!separator) throw new Error ('Date format provided does not contain separator')
  const [A, B, C] = date.split(separator)
  switch (strippedFormat) {
    case 'yyyymmdd':
      return [A, B, C]
    case 'ddmmyyyy':
      return [C, B, A]
    case 'mmddyyyy':
      return [C, A, B]
    default:
      return [A, B, C]
  }
}

/**
 * @description Converts a date object into a string with the UTC date representation as YYYY-MM-DD
 * @param date The date to format
 * @returns A string with the UTC date representation as YYYY-MM-DD
 * @category Utils
 */
export const utcIsoDate = (date: Date) => {
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() + 1
  const day = date.getUTCDate()
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
}

/**
 * @description Parses a date in a given format and returns a Date object
 * with the corresponding UTC date.
 * @param date The date to format
 * @param format The format of the date: 'YYYY-MM-DD', 'DD-MM-YYYY' or 'MM-DD-YYYY'
 * @returns A date object with the corresponding UTC date
 * @category Utils
 */
export default (date: string, format: DateFormat = 'YYYY-MM-DD') => {
  const [year, month, day] = mapDateParts(date, format)
  return new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), 0, 0, 0))
}
