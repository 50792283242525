<template>
  <div class="relative">
    <base-picture
      :src="getImageTransformations(items?.[0]?.src, mediaSize) || defaultImageUrl"
      :alt="items?.[0]?.alt || name"
      :width="mediaSize.width"
      :height="mediaSize.height"
      :lazy
      class="w-full cursor-pointer"
      :props-img="{ class: 'w-a', fetchpriority }"
      data-test-id="product-card-pic"
      @click="$emit('click-gallery-item')"
      @mouseenter="showCarousel = $viewport.lg"
    />
    <transition v-if="items?.[1]?.src" name="fade">
      <template v-if="showSecondaryMedia || showCarousel">
        <lazy-product-card-carousel
          v-if="$feature.enableProductCardGalleryCarousel"
          class="absolute-0"
          :items
          :lazy
          :name
          @click-gallery-item="$emit('click-gallery-item')"
        />
        <component
          :is="items[1].type === 'image' ? BasePicture : BaseVideo"
          v-else
          class="absolute-0 full"
          :class="{ 'bg-black': items[1].type !== 'image' }"
          fit="contain"
          :alt="items[1].alt || name"
          :src="items[1].type === 'image' ? getImageTransformations(items[1].src, mediaSize) : items[1].src"
          :width="mediaSize.width"
          :height="mediaSize.height"
          :lazy
          loop
          autoplay
          data-test-id="product-card-pic-hover"
        />
      </template>
    </transition>
    <slot name="badge" />
  </div>
</template>

<script lang="ts" setup>
import { BasePicture, BaseVideo } from '#components'
import type { ProductGalleryExtended } from '#root/api/clients/product/data-contracts'
import type { ImgHTMLAttributes } from '#types/components/base/picture'

defineProps<{
  items?: ProductGalleryExtended
  lazy?: boolean
  // product Name, used as alt fallback
  name: string
  showSecondaryMedia?: boolean
}>()

defineEmits<{
  'click-gallery-item': []
}>()

const { card: { mediaSize }, defaultImageUrl } = useAppConfig().components.product
const { fetchpriority } = useAttrs() as ImgHTMLAttributes

const showCarousel = ref(false)
</script>
