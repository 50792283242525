<template>
  <div
    class="relative gap-2"
    :class="[layout, layout === 'flex' && 'wrap']"
    :style="[
      layout === 'grid' && {
        gridTemplateColumns: `repeat(${Math.min(filter.options.length, 3)}, minmax(0, 1fr))`,
      },
    ]"
  >
    <base-toggle
      v-for="({ id, label }, i) in filter.options"
      :key="id + i"
      v-model="model"
      :value="id"
      data-test-id="vf-size-filter"
    >
      <span
        class="h-10 w-10 flex grow center b rounded-sm text-center text-xs duration c-grey-10 peer-focus-visible:outline-auto "
        :class="
          model?.includes?.(id)
            ? `b-transparent
                bg-brand-1
                `
            : 'bg-white b-grey-40 c-grey-10'
        "
      >
        <span class="w-full select-none" data-test-id="vf-size-picker-text">
          {{ label }}
        </span>
      </span>
    </base-toggle>
    <div v-if="loading" class="absolute-0 bg-white op-50" />
  </div>
</template>

<script lang="ts" setup>
import type { SizeSmallFilter } from '#types/filters'

withDefaults(
  defineProps<{
    filter: SizeSmallFilter
    layout?: 'flex' | 'grid'
    loading?: boolean
  }>(),
  {
    layout: 'flex'
  }
)

const model = defineModel<string[]>()
</script>
