<template>
  <section
    v-if="appliedFilters.length"
    :aria-label="heading || $t.appliedFilters"
    class="w-full space-y-2 <lg:space-y-4 "
  >
    <h2 v-if="heading" class="text-sm fw-bold">
      {{ heading }}
    </h2>
    <div class="f-col gap-2" :class="{ 'lg:flex-row lg:gap-4 lg:between': !sideFilters }">
      <div
        :id="`${id}-content`"
        ref="appliedFiltersRef"
        role="region"
        :aria-labelledby="id"
        class="relative flex gap-2 overflow-y-hidden transition-height,max-height wrap "
        :style="{ maxHeight: expanded ? scrollHeight : maxHeight }"
      >
        <vf-pill
          v-for="(item, i) in appliedFilters"
          :key="i + item.code"
          removable
          @click="$emit('remove', { code: item.code, id: 'options' in item && item.options?.[0]?.id })"
        >
          <span class="sr-only">{{ $t.removeAppliedFilter }}</span>
          <template v-if="'options' in item && item.options?.[0] && item.type === 'ColorSwatch'" #before>
            <vf-color-swatch
              :key="item.options[0].color"
              :color="item.options[0].color"
              class="block h-4 w-4 overflow-hidden rounded-full"
            />
          </template>
          <template v-if="item.selected && item.type === 'TilePercentage' && !item.selected.includes('%')" #after>
            %
          </template>
          <template v-if="item.selected && item.type === 'PriceInput'">
            {{ useFormattedPrice(+item.selected[0], currency, { trailingZeroDisplay: 'stripIfInteger' }) }}
            -
            {{ useFormattedPrice(+item.selected[1], currency, { trailingZeroDisplay: 'stripIfInteger' }) }}
          </template>
          <template v-else-if="item.selected && item.type === 'Search'">
            {{ item.selected[0] }}
          </template>
          <template v-else-if="'options' in item && item.options">
            <template v-for="({ label, id: optionId }) in item.options" :key="optionId">
              {{ label }}
            </template>
          </template>
        </vf-pill>
      </div>
      <div class="mt-a h-6 flex shrink-0 gap-2 between ">
        <vf-pill
          v-if="isOverflowing"
          :id
          :aria-expanded="expanded"
          :aria-controls="`${id}-content`"
                    @click="expanded = !expanded"
        >
          <template #before>
            <vf-icon :name="expanded ? 'minus' : 'plus'" />
          </template>
          {{ expanded ? $t.seeLess : $t.seeMore }}
        </vf-pill>
        <base-button class="ml-a shrink-0 text-sm underlined" @click="$emit('clear')">
          {{ $t.clearAll }}
        </base-button>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { Responsive } from '#types/common'
import type { FilterItem, FiltersMap } from '#types/filters'

const props = withDefaults(defineProps<{
  filters: FiltersMap
  heading?: string
  currency?: string
  maxCollapsedRows?: Responsive<1 | 2>
  sideFilters?: boolean
}>(), {
  maxCollapsedRows: () => ({ sm: 1, md: 1, lg: 1 })
})

defineEmits<{
  remove: [payload: { code: string, id: string }]
  clear: []
}>()

const { $viewport } = useNuxtApp()
const id = useId()
const appliedFiltersRef = ref<HTMLDivElement>()
const expanded = ref(false)
const maxRows = computed(() => props.maxCollapsedRows[$viewport.breakpoint]!)

/**
 * Get the selected filter pills from the prop filters.
 * @returns [] - ComputedRef<FilterItem[]>
 */
const appliedFilters = computed(() => Object.values(props.filters)
  /**
   * Filter out the selected options for each filter
   */
  .map((item: FilterItem) => ({
    type: item.type,
    code: item.code,
    selected: item.selected,
    options: 'options' in item ? item.options?.filter(({ id }) => item.selected?.includes(id)) : []
  }))
  /**
   * Flatten the `options` which are grouped in each FilterItem,
   * so the template can easily traverse the array without using a nested v-for
   */
  .reduce<(Omit<FilterItem, 'label' | 'layout' | 'facetId'>)[]>((accumulator, { type, code, options, selected }) =>
    (accumulator.concat(
    /**
     * Price filter and search filter are exceptions which don't have `options`,
     * For price: pill just needs the min&max price that are stored within the `selected` in the form of [min, max]
     * For search: pill just needs the search value with is stored within the `selected` in the form of [searchValue]
     */
      ((type === 'PriceInput' || type === 'Search') && selected?.filter(Boolean).length)
        ? [{ type, code, selected }]
        : (options || []).map((option) =>
            ({ type, code, selected, options: [option] })
          )
    )
    ), []))

const { isOverflowing, maxHeight, scrollHeight } = useOverflowCheck(appliedFiltersRef, {
  maxRows,
  watch: () => appliedFilters.value.length
})
</script>
