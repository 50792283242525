<template>
  <vf-carousel
    ref="gallery"
    loop
    class-controls="op-0 group-focus-within:op-100 group-hover:op-100 duration"
    class="group"
    data-test-id="product-card-gallery"
    @init="onInit"
  >
    <div
      v-for="(item, key) in _items"
      :key
      class="relative w-full"
      :style="{ 'aspect-ratio': mediaSize.width / mediaSize.height }"
    >
      <component
        :is="item.type === 'image' ? BasePicture : BaseVideo"
        ref="media"
        v-bind="item"
        class="absolute-0 full cursor-pointer contain"
        @click="$emit('click-gallery-item')"
      />
      <base-button
        v-if="item.type === 'video'"
        class="absolute right-0 mr-2 rounded-full bg-white p-2 shadow-sm"
        :class="badgePosition === 'bottom' ? 'top-0 mt-2' : 'bottom-0 mb-2'"
        :aria-label="media?.[key].paused ? $t.videoPlay : $t.videoPause"
        @click="media?.[key].toggle"
      >
        <vf-icon :name="media?.[key].paused ? 'play' : 'pause'" size="lg" />
      </base-button>
    </div>
  </vf-carousel>
</template>

<script lang="ts" setup>
import type { ProductGalleryExtended } from '#root/api/clients/product/data-contracts'
import { BasePicture, BaseVideo, VfCarousel } from '#components'

const props = defineProps<{
  items: ProductGalleryExtended
  lazy?: boolean
  // product Name, used as alt fallback
  name: string
}>()

defineEmits<{
  'click-gallery-item': []
}>()

const { badgePosition, maxGalleryItems, mediaSize } = useAppConfig().components.product.card

const gallery = ref<typeof VfCarousel>()
const media = ref<(typeof BasePicture | typeof BaseVideo)[]>()

const _items = computed(() => props.items.slice(0, maxGalleryItems).map((item) => ({
  ...item,
  width: mediaSize.width,
  height: mediaSize.height,
  ...(item.type === 'image'
    ? {
        src: getImageTransformations(item.src, mediaSize),
        alt: item.alt || props.name,
      }
    : {}
  ),
  ...(item.type === 'video'
    ? {
        src: item.src,
        meta: item.meta,
        poster: item.poster,
        loop: true
      }
    : {}
  )
})))

const onInit = () => {
  watch(() => gallery.value?.scroll?.activeItem, (current, previous) => {
    if (media.value?.[previous]?.pause && !media.value[previous].paused.value)
      media.value[previous].pause()
    media.value?.[current]?.play?.()
  })
}
</script>
