/**
 * returns the currency symbol
 * @param {string} locale - User Locale
 * @param {string} currency - Product currency passed from API
 * @category Utils
 */
export const getCurrencySymbol = (locale: string, currency: string) => new Intl.NumberFormat(locale, { style: 'currency', currency })
  .formatToParts(1)
  .find((x: Intl.NumberFormatPart) => x.type === 'currency')
  ?.value || ''
