<template>
  <component
    :is="componentTypes[filter.type]"
    v-model="modelValue"
    v-bind="{ filter, layout, loading, currency }"
  />
</template>

<script lang="ts" setup>
import type { FilterItem } from '#types/filters'

withDefaults(
  defineProps<{
    filter: FilterItem
    layout?: 'flex' | 'grid'
    loading?: boolean
    currency?: string
  }>(),
  {
    layout: 'flex'
  }
)

const modelValue = defineModel<string[]>()

const componentTypes = {
  Checkbox: resolveComponent('filter-option-checkbox'),
  ColorSwatch: resolveComponent('filter-option-color-swatch'),
  PriceInput: resolveComponent('filter-option-price-input'),
  TileExpanded: resolveComponent('filter-option-tile-expanded'),
  TilePercentage: resolveComponent('filter-option-tile-percentage'),
  TileImage: resolveComponent('filter-option-tile-image'),
  TileSquare: resolveComponent('filter-option-tile-square')
}
</script>
